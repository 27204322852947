import React from "react";

const Ebook =() =>{
    return(
        <>
        
        <section className=" packages mt-5 pt-2 mb-5">
            <div className="container-fluid">
            <div className="row">
    <div className="col-md-12 pb-5">
      <div className="packages text-center">
        <h2>EBOOK PUBLISHING PACKAGE</h2>
      </div>
    </div>
  </div>
                <div className="row bg-packages">
                    <div className="col-md-12">
                    <div id="eBook">
        <div class="pricing-table pt-4 pb-4">
          <div class="pricing-card ebook-wd">
            <h3 class="pricing-card-header">Feature Description</h3>
            <ul>
              <li class="package-title pt-5 mt-3">Publishing Support</li>
              <li>Content Uploading Allowed - Unlimited</li>
              <li className="package-title">Editing Services</li>
              <li>Format Editing (Upto 100 Pages)</li>
              <li>Proof Reading</li>
              <li>Cover Design</li>
              <li className="package-title">ISBN Allocation</li>
              <li className="package-title"> Marketing your Masterpiece</li>
              <li>Online Distribution</li>
              <li>Author's Social Circle Promotion</li>
              <li>E-Mail Marketing</li>
              <li>WhatsApp Marketing</li>
              <li>Facebook / Twitter / Instagram Marketing</li>
              <li>Digital Releases</li>
              <li className="package-title"> Sales Report & Profits</li>
              <li>Online Sales Dashboard</li>
              <li>Author Profit Share</li>
              <li>Profit / Royalty Payout</li>
              <li className="package-title"> Author Support Services</li>
              <li>Publishing Support</li>
              <li>Technical Support</li>
              <li>Dedicated Publishing Manager</li>
            </ul>
          </div>

          <div class="pricing-card">
            <h3 class="pricing-card-header">Essential</h3>
            <div class="price"><span>₹3,199</span>+ GST</div>
            <ul>
              <li>Yes</li>
              <li className="package"></li>
              <li>Yes (Two Rounds)</li>
              <li>No</li>
              <li>No</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>
          <div class="pricing-card">
            <h3 class="pricing-card-header">Regular</h3>
            <div class="price"><span>₹6,499</span>+ GST</div>
            <ul>
              <li>Yes </li>
              <li className="package"></li>
              <li>Yes (Two Rounds)</li>
              <li>Yes (Two Rounds)</li>
              <li> Yes Basic (Two Rounds)</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>

          <div class="pricing-card">
            <h3 class="pricing-card-header">Superior</h3>
            <div class="price"><span>₹9,499</span>+ GST</div>
            <ul>
              <li>Yes </li>
              <li className="package"></li>
              <li>Yes (Three Rounds)</li>
              <li>Yes (Two Rounds)</li>
              <li>Yes Basic  (Three Rounds)</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes : Reach 2,000</li>
              <li>Yes : Reach 2,000</li>
              <li>Yes</li>
              <li>No</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>

          <div class="pricing-card">
            <h3 class="pricing-card-header">Premium</h3>
            <div class="price"><span>₹13,999</span>+ GST</div>
            <ul>
              <li>Yes </li>
              <li className="package"></li>
              <li>Yes (Three Rounds)</li>
              <li>Yes (Two Rounds)</li>
              <li>Yes Premium  (Three Rounds)</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes : Reach 3,000</li>
              <li>Yes : Reach 3,000</li>
              <li>Yes</li>
              <li>Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>
        </div>
      </div>

                    </div>
                    </div>
                    </div>
                    </section>


        </>

    )
}
export default Ebook;