import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaStar } from 'react-icons/fa';


const Testimonial = (props) => {
    return (
        <>


            <p > {props.data}</p>
            <div className="testimonial-info d-flex justify-content-between">
                <div className="user d-flex">
                    <div className="user-img">
                        <img src={props.img} className="img-fluid rounded-circle" />
                    </div>
                    <div className="user-name mt-3">
                        <h5>{props.name}</h5>
                        <ul className="quotes-rating d-flex">
                            <li className="bg-rating"><FaStar /></li>
                            <li className="bg-rating"><FaStar /></li>
                            <li className="bg-rating"><FaStar /></li>
                            <li className="bg-rating"><FaStar /></li>
                            <li className="bg-rating"><FaStar /></li>
                        </ul>
                    </div>
                </div>
                <div className="quote-img">
                    <img src="../assets/img/testimonial/quote.png" className="img-fluid" />
                </div>
            </div>


        </>
    );
}
export default Testimonial; 