import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';

function Footer (){
return(
<>
<div className="container-fluid mt-lg-5 footer-shadow bg-footer">
  <footer className=" text-lg-start text-footer">
    <section className="bg-footer">
      <div className="container text-md-start mt-lg-5">
        <div className="row mt-3">
          <div className="col-md-6 col-lg-3 col-xl-3 mx-auto mb-lg-4">
            <div className="footer-logo">
              <img src="../assets/img/logo/bfc-logo.png" alt='logo' className="img-fluid"/>
            </div>
            <p  className="pt-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took
            </p>
            <div className="footer-social-icon">
              <ul className="footer-social pt-2">
                <li className="social-icon"><a href="#" target="_blank"><img src="../assets/img/footer-social-icon/facebook.webp" className="img-fluid"/></a></li>
                <li className="social-icon"><a href="#" target="_blank"><img src="../assets/img/footer-social-icon/linkedin.webp" className="img-fluid"/></a></li>
                <li className="social-icon"><a href="#" target="_blank"><img src="../assets/img/footer-social-icon/instagram.webp" className="img-fluid"/></a></li>
                <li className="social-icon"><a href="#" target="_blank"><img src="../assets/img/footer-social-icon/twitter.webp" className="img-fluid"/></a></li>
                <li className="social-icon"><a href="#" target="_blank"><img src="../assets/img/footer-social-icon/youtube.webp" className="img-fluid"/></a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 mx-auto mb-lg-4 pt-2">
            
            <h4 className="text-uppercase heading pb-4">QUICK LINKS</h4>
            <p>
              <a href="/best-mutual-funds-to-invest-in-2023" className="text-footer foot-link">eBook Publisher</a>
            </p>
            <p>
              <a href="/best-elss-funds"  className="text-footer foot-link">Book Publisher</a>
            </p>
            <p>
              <a href="/best-sip-plans-to-invest"  className="text-footer foot-link">FAQs</a>
            </p>
            <p>
              <a href="/tax-saving-mutual-fund"  className="text-footer foot-link">Print On Demand</a>
            </p>
            <p>
              <a href="/best-mutual-fund-for-lumpsum-investment"  className="text-footer foot-link">Career</a>
            </p>
            
          </div>
          <div className="col-md-6 col-lg-3 col-xl-3 mx-auto mb-lg-4 pt-2">
            <h4 className="text-uppercase heading pb-4">Company Info</h4>
            <p><span className="Address"><FaMapMarkerAlt /> </span>
            <a href="https://goo.gl/maps/Ut3yYcCFsM6fKzTA6" target="_blank"  className="text-footer foot-link">BFC Publications Pvt Ltd, <br /> CP-61, Viraj Khand, Gomti Nagar, Lucknow, Uttar Pradesh 226010</a>
          </p>
          <p><span><FaPhoneAlt /> </span>
          <a href="tel:05223514141" target="_blank" className="text-footer foot-link"> +91-522-3514141</a>
        </p>
        <p><span><FaWhatsapp className="fa-lg"/> </span>
        <a href="https://wa.me/+917347700888" target="_blank" className="text-footer foot-link"> +91-7347700888 </a></p>
        <p><span><FaEnvelope/> </span>
        <a href="https://mail.google.com/mail/u/0/?fs=1&to=support@bfcpublications.com&body=BODY&tf=cm" target="_blank" className="text-footer foot-link"> support@bfcpublications.com</a>
      </p>
      
    </div>
    <div className="col-md-6 col-lg-3 col-xl-3 mx-auto mb-lg-4 pt-2">
      <h4 className="text-uppercase pb-4">LEGAL</h4>
      <p>
        <a href="/best-mutual-funds-to-invest-in-2023" className="text-footer foot-link"> Terms and Conditions</a>
      </p>
      <p>
        <a href="/best-elss-funds"  className="text-footer foot-link">Privacy Policy</a>
      </p>
      <p>
        <a href="/best-sip-plans-to-invest"  className="text-footer foot-link">Legal Disclaimer</a>
      </p>
      <p>
        <a href="/tax-saving-mutual-fund"  className="text-footer foot-link">Refund and Cancellation Policy</a>
      </p>
    </div>
  </div>
</div>
<hr />
<div className="container">
  <div className="row">
    <div className="com-lg-12 col-md-12 col-sm-12 text-footer">
      <p>Since its inception, BFC Publications has been fast gaining mileage over other self publishers in India. If online comparisons are to be believed, even the most prominent book publishers have been struggling to match the prices we offer, primarily owing to their reasonability. This, coupled with our persistence to ensure unmatched quality and time-bound delivery, has propelled us as one of India’s most trusted publishing platforms. Our team of experts leaves no stone unturned to fine-comb errors in manuscripts, and interpret the author’s imagination the way it was originally envisioned. No matter who you are, a first-timer starting a book, or a seasoned author, if you are looking to publish a book, BFC Publications is where you’d like to be.</p>
    </div>
  </div>
</div>
<hr />
<div className="text-center p-3 card border-0 bg-footer">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-lg-12 col-xl-12">
      <h6>© 2022 Copyright: BFC Publications. All Rights Reserved</h6> </div>
    </div>
  </div>
</div>
</section>
</footer>
</div>

</>
);
}
export default Footer;