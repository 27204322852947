import React, { useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaEnvelope, FaUser, FaPhoneAlt, FaLongArrowAltRight, FaStar } from 'react-icons/fa';
import Testimonial from "../components/testimonial";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import sampleData from "./sample";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



const Home = () => {
  const [apidata, setApidata] = useState([]);

  useEffect(() => {
    setApidata(sampleData);

  }, []);

  return (

    <>

      <section className="banner-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="banner-caption">
                <h1>IF WRITING IS YOUR PASSION, PUBLISHING IS OURS.</h1>
                <p>Owing to the sheer weight of the processes involved, shortlisting a self book publisher in India can be overwhelming, especially for first-timers. That's what we are here for. So say goodbye to those online publishing troubles and allow us to craft your manuscript into a masterpiece. Don't you worry, we've got this covered.</p>
              </div>
              <div className="banner-subtext">
                <p><b className="text-red">Formats :</b> Paperback, eBook, Hardcover</p>
                <p><b className="text-red">Genre : </b> Fiction, Non-Fiction, Science, Technology, Poetry, Art and Regional Books etc.</p>
                <p><b className="text-red">Languages : </b> English, Hindi and Other Regional Languages</p>
              </div>
            </div>
            <div className="col-md-5 pt-4">
              <img src="../assets/img/hh.png" className="img-fluid" />
            </div>
            <div className="container home-form">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="form-title">
                    <p>Start Publishing Now</p>
                  </div>
                  <Form>
                    <Row>
                      <Col>
                        <Form.Label><FaUser /></Form.Label>
                        <Form.Control placeholder="First name" />
                      </Col>
                      <Col>
                        <Form.Label><FaEnvelope /></Form.Label>
                        <Form.Control placeholder="Last name" />
                      </Col>
                      <Col>
                        <Form.Label><FaPhoneAlt /></Form.Label>
                        <Form.Control placeholder="Last name" />
                      </Col>
                      <button class="custom-btn-banner" role="button">Submit</button>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="klf">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="klf-caption">
                <h3>BFC PUBLICATIONS PRESENTS</h3>
                <h2>KOSHALA LITERATURE FESTIVAL 2022</h2>
                <p>Held between 4-6 November 2022, KLF-Awadh was a three-day long celebration revolving around the rich heritage of Awadh, featuring the biggest names in the fields of Arts, Literature, Music, Filmmaking, and much more.</p>
                <button class="custom-btn" role="button">Read More</button>
              </div>
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col-md-6 pb-3">
                  <a href="#">
                    <div className="klf-icon_box">
                      <img src="../assets/img/klf/books.png" className="img-fluid" />
                      <p>Book Stall</p>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 pb-3">
                  <a href="#">
                    <div className="klf-icon_box">
                      <img src="../assets/img/klf/lounge.png" className="img-fluid" />
                      <p>BFC Lounge</p>
                    </div>
                  </a>
                </div>
                <div className="col-md-6">
                  <a href="#">
                    <div className="klf-icon_box">
                      <img src="../assets/img/klf/interview.png" className="img-fluid" />
                      <p>KLF Sessions</p>
                    </div>
                  </a>
                </div>
                <div className="col-md-6">
                  <a href="#">
                    <div className="klf-icon_box">
                      <img src="../assets/img/klf/image-gallery.png" className="img-fluid" />
                      <p>Gallery</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-about">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-stretch">
              <div className="align-self-center">
                <div className="home-about-caption">
                  <h2>ABOUT BFC PUBLICATIONS</h2>
                  <p>BFC Publications has managed to bring together a group of young and dynamic professionals, all experts in their respective fields. The sole purpose of doing so is to build a platform that does justice to those who are passionate about telling stories but don't have the means to get them published by a self book publisher in India.</p>
                  <p>What most budding authors fail to realise is that a book is rated not just on how well it is written, but also on the response it garners. This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors, but also to educate them about the nuances of self- publishing in India.</p>
                </div>
                <button class="custom-btn" role="button">Read More</button>
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="home-about-img">
                <img src="../assets/img/home-about.webp" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-about bg-blue">
        <div className="container">
          <div className="row">
            <div className="col-md-5 d-flex align-items-stretch">
              <div className="align-self-center">
                <div className="home-about-caption">
                  <h2>SERVICES WE OFFER</h2>
                  <p>There are a range of services authors can choose from if they are looking to publish a book in India. Print-on-demand self-publishing, eBook publishing, ghostwriting, copy-editing, and social media marketing are among them, to name a few. Based on the shortlisted package, authors can avail these services to publish or market their books. Besides this, they can also get publishing packages tailored to suit their requirements.</p>
                </div>
                <button class="custom-btn" role="button">Explore More</button>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row mb-4">
                <div className="col-md-4">
                  <div className="home-service-info">
                    <img src="../assets/img/service-icon/graphic-design.png" className="img-fluid" />
                    <p>Basic & Premium Design with High Resolution Graphics</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="home-service-info">
                    <img src="../assets/img/service-icon/ai.png" className="img-fluid" />
                    <p>Customised Interior with Enhanced Layout & Superior Illustrations</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="home-service-info">
                    <img src="../assets/img/service-icon/clipboard.png" className="img-fluid" />
                    <p>Customised Publishing Packages Based On Author Needs</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="home-service-info">
                    <img src="../assets/img/service-icon/social-strategy.png" className="img-fluid" />
                    <p>Extensive Social Media Ad Campaign Covering Facebook, Instagram, Twitter & WhatsApp</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="home-service-info">
                    <img src="../assets/img/service-icon/accounting.png" className="img-fluid" />
                    <p>Freedom to Choose the Selling Price Based on the Projections of  Royalty Calculator</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="matrix-title text-center">
                <h2>OUR SELF PUBLISHING MATRIX</h2>
                <p>There is a very simple, pre-defined workflow put in place, which is to be followed by both, our publications team, as well as the authors, regardless of what services they chose to avail, be it print- on-demand self-publishing or eBook publishing. </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="matrix-info">
                <div class="main-timeline">
                  <a href="/process" class="timeline">
                    <div class="timeline-icon"><span class="year"><img src="../assets/img/matrix/registration.png" className="img-fluid" /></span></div>
                    <div class="timeline-content">
                      <h3 class="title">Consultation & Registration</h3>
                      <p class="description">
                        Leverage the know-how of our expert consultants to identify the publishing solution that best suits your needs, or get one tailored.
                      </p>
                      <p className="read-more-link">Read More <span> <FaLongArrowAltRight /></span></p>
                    </div>
                  </a>
                  <a href="/process" class="timeline">
                    <div class="timeline-icon"><span class="year"><img src="../assets/img/matrix/signature.png" className="img-fluid" /></span></div>
                    <div class="timeline-content">
                      <h3 class="title"> Contract Signing</h3>
                      <p class="description">
                        Enter into a contract with us to safeguard the intellectual rights of your manuscript. This also ensures that both parties, you and BFC uphold
                      </p>
                      <p className="read-more-link">Read More <span> <FaLongArrowAltRight /></span></p>
                    </div>
                  </a>
                  <a href="/process" class="timeline">
                    <div class="timeline-icon"><span class="year"><img src="../assets/img/matrix/check.png" className="img-fluid" /></span></div>
                    <div class="timeline-content">
                      <h3 class="title">Manuscript Submission</h3>
                      <p class="description">
                        Submit your manuscript with us to get the ball rolling. A dedicated publishing manager/consultant shall be assigned to you
                      </p>
                      <p className="read-more-link">Read More <span> <FaLongArrowAltRight /></span></p>
                    </div>
                  </a>
                  <a href="/editorial-services" class="timeline">
                    <div class="timeline-icon"><span class="year"><img src="../assets/img/matrix/edit.png" className="img-fluid" /></span></div>
                    <div class="timeline-content">
                      <h3 class="title">Proofreading & Format Editing</h3>
                      <p class="description">
                        Our team of proof-readers and copy-editors will get working on your book, fine-combing the manuscript for errors.
                      </p>
                      <p className="read-more-link">Read More <span> <FaLongArrowAltRight /></span></p>
                    </div>
                  </a>
                  <a href="/designing-services" class="timeline">
                    <div class="timeline-icon"><span class="year"><img src="../assets/img/matrix/design.png" className="img-fluid" /></span></div>
                    <div class="timeline-content">
                      <h3 class="title">Designing </h3>
                      <p class="description">
                        Our graphic designers and illustrators go to work on the interior and cover of the book. This is done keeping in mind the brief provided
                      </p>
                      <p className="read-more-link">Read More <span> <FaLongArrowAltRight /></span></p>
                    </div>
                  </a>
                  <a href="/marketing-services" class="timeline">
                    <div class="timeline-icon"><span class="year"><img src="../assets/img/matrix/social-media.png" className="img-fluid" /></span></div>
                    <div class="timeline-content">
                      <h3 class="title">Marketing & Online Distribution </h3>
                      <p class="description">
                        Our graphic designers and illustrators go to work on the interior and cover of the book. This is done keeping in mind the brief provided </p>
                      <p className="read-more-link">Read More <span> <FaLongArrowAltRight /></span></p>

                    </div>
                  </a>
                  <a href="/royalty-calculator" class="timeline">
                    <div class="timeline-icon"><span class="year"><img src="../assets/img/matrix/rupee.png" className="img-fluid" /></span></div>
                    <div class="timeline-content">
                      <h3 class="title">Royalty </h3>
                      <p class="description">
                        Our graphic designers and illustrators go to work on the interior and cover of the book. This is done keeping in mind the brief provided
                      </p>
                      <p className="read-more-link">Read More <span> <FaLongArrowAltRight /></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-whyus bg-blue">
        <div className="container">
          <div className="row">
            <div className="col-md-5 d-flex align-items-stretch">
              <div className="align-self-center">
                <div className="home-whyus-caption">
                  <h2>WHY US</h2>
                  <p>If online comparisons are to be believed, even the most prominent eBook publishers in India have been struggling to match our package prices and quality. The sheer reasonability of our pricing, coupled with our persistence to deliver quality, has propelled us as a household name in the online book publishing sector.</p>
                  <p>Presently, the digital universe is flooded with eBook publishers in India who make tall claims just for the sake of pushing their sales volumes. Moreover, the practice of hiding damaging sub-clauses under fine prints is making things worse.</p>
                </div>
                <button class="custom-btn" role="button">Explore More</button>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="home-whyus-info">
                    <img src="../assets/img/why-us/rights.png" className="img-fluid" />
                    <h5>Intellectual Rights</h5>
                    <p>Safeguard the intellectual rights of your work by entering into a contract with BFC publications. This also makes sure that the agreed terms of publishing your work are inked in black and white, and there is no dilly dallying on our part.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="home-whyus-info">
                    <img src="../assets/img/why-us/share.png" className="img-fluid" />
                    <h5>Profit Sharing</h5>
                    <p>We make sure that you don’t have to share the spoils when it comes to the profits made through the sale of your book. Authors are entitled to receive almost every penny earned through sales proceeds.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="home-whyus-info pb-5">
                    <img src="../assets/img/why-us/support.png" className="img-fluid" />
                    <h5>Marketing Support</h5>
                    <p>No stone is left unturned to magnify your book’s digital footprint, by putting into action, extensively researched digital marketing gimmicks.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="home-whyus-info">
                    <img src="../assets/img/why-us/book.png" className="img-fluid" />
                    <h5>Book Interior & Cover</h5>
                    <p>Our team of format editors, illustrators, and graphic designers make sure that the final output is top-notch and the author's vision is interpreted in the best way possible, be it the page layout, its margins, or the cover.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="packages">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 pb-3">
              <div className="packages text-center">
                <h2>PLANS</h2>
              </div>
            </div>
          </div>
              <Tabs>
              <div className="row justify-content-center">
            <div className="col-md-3 pb-4">
                <TabList>
                  <Tab>Paperback</Tab>
                  <Tab>eBook</Tab>
                </TabList>
                </div>
                </div>
              <div className="row bg-packages">
              <div className="col-md-12">
                <TabPanel>
                <div id="Paperback">
                <div class="pricing-table">
                  <div class="pricing-card paperback-wd">
                    <h3 class="pricing-card-header">Feature Description</h3>
                    <ul>
                      <li class="package-title pt-5 mt-3">Editing Services</li>
                      <li>Format Editing</li>
                      <li>Proof Reading</li>
                      <li>Cover Design</li>
                      <li className="package-title">ISBN Allocation</li>
                      <li className="package-title"> Marketing your Masterpiece</li>
                      <li>Author's Social Circle Promotion</li>
                      <li>E-Mail Marketing</li>
                    </ul>
                  </div>

                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Essential</h3>
                    <div class="price"><span>₹6,699</span>+ GST</div>
                    <ul>
                      <li>Yes (Two Rounds)</li>
                      <li>Yes (Two Rounds)</li>
                      <li>Basic  (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                      <li>Yes</li>
                      <li>No</li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>
                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Regular</h3>
                    <div class="price"><span>₹13,999</span>+ GST</div>
                    <ul>
                      <li>Yes (Two Rounds)</li>
                      <li>Yes (Two Rounds)</li>
                      <li>Basic  (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                      <li>Yes</li>
                      <li>Yes : Reach 2,000</li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Superior</h3>
                    <div class="price"><span>₹23,499</span>+ GST</div>
                    <ul>
                      <li>Yes (Two Rounds)</li>
                      <li>Yes (Three Rounds)</li>
                      <li>Premium  (Three Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                      <li>Yes</li>
                      <li>Yes : Reach 3,000</li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Premium</h3>
                    <div class="price"><span>₹47,499</span>+ GST</div>
                    <ul>
                      <li>Yes (Three Rounds)</li>
                      <li>Yes (Three Rounds)</li>
                      <li>Premium  (Three Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                      <li>Yes</li>
                      <li>Yes : Reach 5,000</li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Elite</h3>
                    <div class="price"><span>₹73,499</span>+ GST</div>
                    <ul>
                      <li>Yes (Three Rounds)</li>
                      <li>Yes (Three Rounds)</li>
                      <li>Premium  (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                      <li>Yes</li>
                      <li>Yes : Reach 7,000</li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>
                </div>
              </div>
                </TabPanel>
                <TabPanel>
                <div id="eBook">
                <div class="pricing-table">
                  <div class="pricing-card ebook-wd">
                    <h3 class="pricing-card-header">Feature Description</h3>
                    <ul>
                      <li class="package-title pt-5 mt-3">Publishing Support</li>
                      <li>Content Uploading Allowed - Unlimited</li>
                      <li className="package-title">Editing Services</li>
                      <li>Format Editing (Upto 100 Pages)</li>
                      <li>Proof Reading</li>
                      <li>Cover Design</li>
                      <li className="package-title">ISBN Allocation</li>
                      <li className="package-title"> Marketing your Masterpiece</li>
                    </ul>
                  </div>

                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Essential</h3>
                    <div class="price"><span>₹3,199</span>+ GST</div>
                    <ul>
                      <li>Yes</li>
                      <li className="package"></li>
                      <li>Yes (Two Rounds)</li>
                      <li>No</li>
                      <li>No</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>
                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Regular</h3>
                    <div class="price"><span>₹6,499</span>+ GST</div>
                    <ul>
                      <li>Yes </li>
                      <li className="package"></li>
                      <li>Yes (Two Rounds)</li>
                      <li>Yes (Two Rounds)</li>
                      <li> Yes Basic (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Superior</h3>
                    <div class="price"><span>₹9,499</span>+ GST</div>
                    <ul>
                      <li>Yes </li>
                      <li className="package"></li>
                      <li>Yes (Three Rounds)</li>
                      <li>Yes (Two Rounds)</li>
                      <li>Yes Basic  (Three Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card">
                    <h3 class="pricing-card-header">Premium</h3>
                    <div class="price"><span>₹13,999</span>+ GST</div>
                    <ul>
                      <li>Yes </li>
                      <li className="package"></li>
                      <li>Yes (Three Rounds)</li>
                      <li>Yes (Two Rounds)</li>
                      <li>Yes Premium  (Three Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>
                </div>
              </div>
                </TabPanel>
                </div>
                 </div>
              </Tabs>
              <div className="row  bg-packages">
              <div className="col-md-12">
                <div className="text-center">
                <a href="/publishing-package" className="custom-btn">Explore More</a>
                </div>
                </div>
                </div>
            </div>
      </section>

      <section className="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="home-testimonial-caption">
                <h2>APPLAUSES</h2>
                <h3>What our clients say about <br />our Company.</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
              </div>
            </div>
            <div className="col-md-6">
              <Carousel interval={3000} autoPlay={true} infiniteLoop={true} showArrows={false} showStatus={false}>
                {sampleData ? sampleData.map((data) => {
                  return <div className="testimonial-content">
                    <Testimonial
                      data={data.content}
                      img={data.img}
                      name={data.name} />
                  </div>
                }) : null}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="distribution-platforms">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pb-4">
              <div className="home-distribution text-center pb-3">
                <h2>OUR DISTRIBUTION PLATFORMS</h2>
              </div>
            </div>
          </div>
          <Carousel interval={3000} autoPlay={true} infiniteLoop={true} showArrows={false} showStatus={false}>
            <div className="row">
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src="../assets/img/distribution-platform/1.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src="../assets/img/distribution-platform/2.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src="../assets/img/distribution-platform/3.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src="../assets/img/distribution-platform/4.png" className="img-fluid" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src="../assets/img/distribution-platform/5.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src="../assets/img/distribution-platform/6.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src="../assets/img/distribution-platform/7.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src="../assets/img/distribution-platform/8.png" className="img-fluid" />
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section>

      


    </>
  );
}
export default Home;