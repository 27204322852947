import React from "react";

const PaperBack =() =>{
    return(
        <>
        
        <section className="packages mt-5 pt-2 mb-5">
            <div className="container-fluid">
            <div className="row">
    <div className="col-md-12 pb-5">
      <div className="packages text-center">
        <h2>PAPERBACK PUBLISHING PACKAGE</h2>
      </div>
    </div>
  </div>
                <div className="row bg-packages">
                    <div className="col-md-12">
                    <div id="Paperback">
        <div class="pricing-table">
          <div class="pricing-card paperback-wd">
            <h3 class="pricing-card-header">Feature Description</h3>
            <ul>
              <li class="package-title pt-5 mt-3">Editing Services</li>
              <li>Format Editing</li>
              <li>Proof Reading</li>
              <li>Cover Design</li>
              <li className="package-title">ISBN Allocation</li>
              <li className="package-title"> Marketing your Masterpiece</li>
              <li>Author's Social Circle Promotion</li>
              <li>E-Mail Marketing</li>
              <li>WhatsApp Marketing</li>
              <li>SMS Marketing</li>
              <li>Facebook / Twitter / Instagram Marketing</li>
              <li>Facebook + Twitter + Instagram Marketing</li>
              <li>Youtube Marketing - Trailer Launch</li>
              <li>Amazon Ads (1 Week Ad on Amazon)</li>
              <li>Digital Releases</li>
              <li>Author Website Creation</li>
              <li>Goodreads Account Set Up</li>
              <li>Amazon Author Page Set Up</li>
              <li>Amazon Pre Order</li>
              <li>Google Ads + Kindle Ads + Youtube Bumper Ads </li>
              <li>Online Listing & Distribution</li>
              <li>Offline Distribution</li>
              <li className="package-title"> Sales Report & Profits</li>
              <li>Online Sales Dashboard</li>
              <li>Author Profit Share</li>
              <li>Profit / Royalty Payout</li>
              <li className="package-title"> Author Support Services</li>
              <li>Inventory Manager</li>
              <li>Technical Support</li>
              <li>Dedicated Publishing Manager</li>
              <li>Non Exclusive Agreement</li>
              <li>Complimentary Author Copies</li>
              <li>Additional Author Copies</li>
              <li className="package-title"> Book Specifications</li>
              <li>Number of Pages Allowed</li>
              <li>Content Allowed</li>
              <li className="p-20">Paper Type</li>
              <li>Book Size</li>
              <li>Lamination</li>
              <li>Book Interior</li>
              <li>Book Cover</li>
              <li className="package-title">eBook Creation & Distribution</li>
            </ul>
          </div>

          <div class="pricing-card">
            <h3 class="pricing-card-header">Essential</h3>
            <div class="price"><span>₹6,699</span>+ GST</div>
            <ul>
              <li>Yes (Two Rounds)</li>
              <li>Yes (Two Rounds)</li>
              <li>Basic  (Two Rounds)</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li className="p-20">No</li>
              <li className="p-20">No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li className="p-20">No</li>
              <li>Yes</li>
              <li>No</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>6</li>
              <li>Order at Subsidised Price</li>
              <li className="package"></li>
              <li>100</li>
              <li>Text and Images</li>
              <li>Creamy or White <br />(58 or 70 GSM)</li>
              <li>5*8 / 6*9</li>
              <li>Gloss / Matte</li>
              <li>Black & White</li>
              <li>MultiColor 250 GSM</li>
              <li className="package-title"> Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>
          <div class="pricing-card">
            <h3 class="pricing-card-header">Regular</h3>
            <div class="price"><span>₹13,999</span>+ GST</div>
            <ul>
              <li>Yes (Two Rounds)</li>
              <li>Yes (Two Rounds)</li>
              <li>Basic  (Two Rounds)</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes : Reach 2,000</li>
              <li>Yes : Reach 2,000</li>
              <li>No</li>
              <li className="p-20">No</li>
              <li className="p-20">Yes</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li className="p-20">No</li>
              <li>Yes</li>
              <li>No</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>8</li>
              <li>Order at Subsidised Price</li>
              <li className="package"></li>
              <li>150</li>
              <li>Text and Images</li>
              <li>Creamy or White <br />(58 or 70 GSM)</li>
              <li>5*8 / 6*9</li>
              <li>Gloss / Matte</li>
              <li>Black & White</li>
              <li>MultiColor 250 GSM</li>
              <li className="package-title"> Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>

          <div class="pricing-card">
            <h3 class="pricing-card-header">Superior</h3>
            <div class="price"><span>₹23,499</span>+ GST</div>
            <ul>
              <li>Yes (Two Rounds)</li>
              <li>Yes (Three Rounds)</li>
              <li>Premium  (Three Rounds)</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes : Reach 3,000</li>
              <li>Yes : Reach 3,000</li>
              <li>No</li>
              <li className="p-20">No</li>
              <li className="p-20">Yes</li>
              <li>No</li>
              <li>No</li>
              <li>Yes</li>
              <li>Yes (1 Year)</li>
              <li>No</li>
              <li>No</li>
              <li>No</li>
              <li className="p-20">No</li>
              <li>Yes</li>
              <li>No</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>10</li>
              <li>Order at Subsidised Price</li>
              <li className="package"></li>
              <li>200</li>
              <li>Text and Images</li>
              <li>Creamy or White <br />(58 or 70 GSM)</li>
              <li>5*8 / 6*9</li>
              <li>Gloss / Matte</li>
              <li>Black & White</li>
              <li>MultiColor 250 GSM</li>
              <li className="package-title"> Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>

          <div class="pricing-card">
            <h3 class="pricing-card-header">Premium</h3>
            <div class="price"><span>₹47,499</span>+ GST</div>
            <ul>
              <li>Yes (Three Rounds)</li>
              <li>Yes (Three Rounds)</li>
              <li>Premium  (Three Rounds)</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes : Reach 5,000</li>
              <li>Yes : Reach 5,000</li>
              <li>Yes : Reach 5,000</li>
              <li className="p-20">No</li>
              <li className="p-20">Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes (1 Year)</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li className="p-20">No</li>
              <li>Yes</li>
              <li>No</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>10</li>
              <li>Order at Subsidised Price</li>
              <li className="package"></li>
              <li>300</li>
              <li>Text and Images</li>
              <li>Creamy or White <br />(58 or 70 GSM)</li>
              <li>5*8 / 6*9</li>
              <li>Gloss / Matte</li>
              <li>Black & White</li>
              <li>MultiColor 250 GSM</li>
              <li className="package-title"> Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>

          <div class="pricing-card">
            <h3 class="pricing-card-header">Elite</h3>
            <div class="price"><span>₹73,499</span>+ GST</div>
            <ul>
              <li>Yes (Three Rounds)</li>
              <li>Yes (Three Rounds)</li>
              <li>Premium  (Two Rounds)</li>
              <li className="package-title"> Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes : Reach 7,000</li>
              <li>Yes : Reach 7,000</li>
              <li>Yes : Reach 7,000</li>
              <li className="p-20">No</li>
              <li className="p-20">Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes (1 Year)</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li className="p-20">Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>100%</li>
              <li>Quarterly</li>
              <li className="package"></li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>Yes</li>
              <li>10</li>
              <li>Order at Subsidised Price</li>
              <li className="package"></li>
              <li>350</li>
              <li>Text and Images</li>
              <li>Creamy or White <br />(58 or 70 GSM)</li>
              <li>5*8 / 6*9</li>
              <li>Gloss / Matte</li>
              <li>Black & White</li>
              <li>MultiColor 250 GSM</li>
              <li className="package-title"> Yes</li>
            </ul>
            <a href="#" class="custom-btn">GET NOW</a>
          </div>
        </div>
      </div>
                    </div>
                </div>
            </div>
        </section>
        


        </>

    )
}
export default PaperBack;