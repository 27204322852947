import React from "react";

const About = () => {
    return (
        <>

            <section className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-title">
                                <h2>ABOUT BFC PUBLICATIONS</h2>
                                <p>IF WRITING IS YOUR PASSION, PUBLISHING IS OURS.</p>
                            </div>
                            <div className="about-desc">
                                <p>BFC Publications has managed to bring together a group of young and dynamic professionals, all experts in their respective fields. The sole purpose of doing so is to build a platform that does justice to those who are passionate about telling stories but don't have the means to get them published.</p>
                                <p>What most budding authors fail to realise is that a book is rated not just on how well it is written, but also on the response it garners. This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors, but also to educate them about the nuances of writing and publishing. Expanding a book's digital footprint is a conundrum that can be too much for first-timers to decipher, an aspect our digital marketing experts have got covered. So trust us when we tell you that we pull out all the stops to push your book's visibility.</p>
                            </div>
                        </div>
                        <div className="col-md-6 pt-5">
                            <div className="about-img">
                                <img src="../assets/img/about.webp" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-bg">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-md-7">
                            <div className="about-desc">
                                <p>Interpreting the author's vision and translating it into what he/she desires is what we fancy. To make sure this happens in a satisfactory capacity, our publishing managers remain in constant touch with the authors and pass on necessary directives to those working on your book. This also ensures that you are aware of what is happening in the backdrop, at all times.</p>
                                <p>We never want our authors to end up feeling tricked. This was our primary focus while drafting the contract that the authors are supposed to sign before manuscript submission. This ironclad contract makes sure both the parties, you and us, uphold our end of the deal, be it the timely launch of your book, or the terms of payment.</p>
                                <p>Proofreading and editing of books is another aspect we don't take lightly. The download of your conversations with the publishing team is passed on to the proofreaders and editors. This is done to make sure that the aspects you most love about your story are not edited out during the fine-combing of errors. Instead, the editors concerned are advised to work their way around such mistakes and still make sure that the final text is top-notch.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="company-info">
                <div className="container">
                    <div class="row">
                        <div class="col-md-3 col-sm-6">
                            <div class="counter">
                                <div class="counter-icon">
                                    <img src="../assets/img/about-icon/goal.png" className="img-fluid" />
                                </div>
                                <span class="counter-value">15</span>
                                <h3>Years Experience</h3>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="counter green">
                                <div class="counter-icon">
                                    <img src="../assets/img/about-icon/costumer.png" className="img-fluid" />
                                </div>
                                <span class="counter-value">800+</span>
                                <h3>Happy Clients</h3>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="counter green">
                                <div class="counter-icon">
                                    <img src="../assets/img/about-icon/books.png" className="img-fluid" />
                                </div>
                                <span class="counter-value">500+</span>
                                <h3>Books Publish</h3>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="counter green">
                                <div class="counter-icon">
                                    <img src="../assets/img/about-icon/writer.png" className="img-fluid" />
                                </div>
                                <span class="counter-value">200+</span>
                                <h3>Book Authors</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bfcteam">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="bfcteam-text pb-4">
                                <h2>THE PATH BREAKERS</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-5">
                        <div className="col-md-5">
                            <img src="../assets/img/team/Vikas-Sir.webp" className="img-fluid" />
                        </div>
                        <div className="col-md-7 d-flex align-items-stretch">
                            <div className="align-self-center">
                                <div className="about-info">
                                    <div className="bfcteam-title pb-3">
                                        <h3> Vikas Singh
                                            <span className="profile-desc"> ( Vice President )</span>
                                        </h3>
                                    </div>
                                    <div className="bfcteam-desc">
                                        <p>With a Corporate experience of more than a Decade and Diverse Exposure of Automobiles, Consumer Durables, Education and Wealth Management, Vikas brings with him the Cutting Edge required to effectively manage different Strategic Business Units.</p>
                                        <p>He has headed the Revenue Vertical since Inception and was instrumental in Designing, Testing & Modifying Working Processes and Strategies during the foundation year. Presently he works on Strategy Formation, Package Upgradation, Innovative Working Methods, Revenue Projections and a few other areas.</p>

                                        <p>A die hard reader at heart, he loves being involved with Books and thus leaves no stone unturned to ensure Authors get the Best of everything when they come onboard with BFC.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row  mt-5">
                        <div className="col-md-7 d-flex align-items-stretch">
                            <div className="align-self-center">
                                <div className="about-info">
                                    <div className="bfcteam-title pb-3">
                                        <h3> Deepti Bansal
                                            <span className="profile-desc"> ( Asst. Vice President )</span>
                                        </h3>
                                    </div>
                                    <div className="bfcteam-desc">
                                        <p>As Assistant Vice President, Deepti is responsible for supporting the company's strategies for growth and development. She oversees several different areas, such as day-to-day working, executes Revenue Strategies and works on Business Expansion.</p>
                                        <p>She holds a Master’s degree in Finance & Marketing and has worked for over 6 years in the Financial Industry. The numerous hours she spent scanning and manipulating complex data, cultivated in her the skills needed to lead a group as dynamic as her Team.</p>
                                        <p>“Publishing Consultants, however brilliant, need to be pointed in the right direction. Someone needs to take responsibility for extracting the best out of them.” she says.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src="../assets/img/team/Deepti-Ma'am.png" className="img-fluid" />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-12 text-center">
                            <div className="bfcteam-text pb-5">
                                <h2>THE GO GETTERS !</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="team-card text-center">
                                <div className="team-content">
                                    <div class="team-content-overlay fadeIn-bottom"></div>
                                    <div className="team-img">
                                        <img src="../assets/img/team/shweta.webp" className="img-fluid" />
                                    </div>
                                    <div className="team-descrition">
                                        <div className="team-overlay-text">
                                            <p>Shweta is one of our most passionate Publishing Consultants with abundant marketing experience under her belt. She has been with the team for 3-odd years and has helped numerous Authors publish and market their books.</p>
                                            <p>"A book is as good as its cover, at least in this digital age", she opines. "Yes, this contradicts an old saying about books and not judging on covers, but like it or not, covers go a long in selling books in this age of trending hashtags."</p>
                                            <p>"To market a book sufficiently we have to risk being bold and in the face. How else does one compete nowadays?"</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="pt-3"><span class="sub-color"> Shweta Mishra </span></h2>
                                <p class="pb-3"><span class="profile-text"> Sr. Publishing Consultant </span> </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="team-card text-center">
                                <div className="team-content">
                                    <div class="team-content-overlay fadeIn-bottom"></div>
                                    <div className="team-img">
                                        <img src="../assets/img/team/chitra.webp" className="img-fluid" />
                                    </div>
                                    <div className="team-descrition">
                                        <div className="team-overlay-text">
                                            <p>With more than 7 years of Quality Control and Customer Support experience under her belt, Chitra is a master in outlining publishing strategies.
                                            She understands the self-publishing space pretty well and makes sure the strategies she outlines are in line with Author inputs and priorities.</p>
                                            <p>"A little empathy can help achieve what many businesses fail to do consistently, deliver customer satisfaction", she claims. "My time as a Customer Support professional has given me the ears needed to listen to Authors patiently, understand their needs and help them make smart and informed decisions. Publishing strategies need to be outlined based on the inputs of Authors."</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="pt-3"><span class="sub-color"> chitra Pal </span></h2>
                                <p class="pb-3"><span class="profile-text"> Sr. Publishing Consultant </span> </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="team-card text-center">
                                <div className="team-content">
                                    <div class="team-content-overlay fadeIn-bottom"></div>
                                    <div className="team-img">
                                        <img src="../assets/img/team/neha.webp" className="img-fluid" />
                                    </div>
                                    <div className="team-descrition">
                                        <div className="team-overlay-text">
                                            <p>Much like her colleagues, Neha is a Publishing Consultant and a valued part of Team BFC. She is thorough and unrelenting when it comes to delivering Author satisfaction.</p>
                                            <p>"The job warrants that I interact with Authors, understand their needs and offer solutions to address those needs. This becomes easier when you are aware of the process,” she clarifies.</p>
                                            <p>"During my initial days here, I made a conscious effort to understand the underlying steps of the book publishing process. It is paying rich dividends now, as this know-how helps me make the adjustments needed to align the publishing process with Author needs."</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="pt-3"><span class="sub-color"> Neha Sahu </span></h2>
                                <p class="pb-3"><span class="profile-text"> Publishing Consultant </span> </p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="team-card text-center">
                                <div className="team-content">
                                    <div class="team-content-overlay fadeIn-bottom"></div>
                                    <div className="team-img">
                                        <img src="../assets/img/team/anamika.webp" className="img-fluid" />
                                    </div>
                                    <div className="team-descrition">
                                        <div className="team-overlay-text">
                                            <p>Anamika works as a Publishing Consultant with Team BFC. She is required to handhold Authors looking to get published.</p>
                                            <p>“I usually approach the task by explaining in detail, the services Authors can avail and the marketing tools that can be deployed to advertise their beloved masterpieces,” she explains in her usual optimistic tone.</p>
                                            <p>“I’ve spent a significant portion of my career interacting with people and I like it, you all know this. Talking to Authors, especially the new ones, helps me understand their motivations. Getting to interpret human emotions in their purest form; that’s all the incentive I need,” she concludes.</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="pt-3"><span class="sub-color"> Anamika Singh </span></h2>
                                <p class="pb-3"><span class="profile-text"> Publishing Consultant </span> </p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="team-card text-center">
                                <div className="team-content">
                                    <div class="team-content-overlay fadeIn-bottom"></div>
                                    <div className="team-img">
                                        <img src="../assets/img/team/saumya.webp" className="img-fluid" />
                                    </div>
                                    <div className="team-descrition">
                                        <div className="team-overlay-text">
                                            <p>Saumya is a postgraduate and an MBA who excels in outlining marketing strategies. So far, she has helped several newbie authors publish their books and has been lauded by many for the extra mile she walks to market the book.</p>
                                            <p>“Guilty as charged,” she says with a sheepish grin when asked about her tendency to go out of the way to help first-timers. “I make it a point to explain to the authors in detail, the services and marketing tools they can leverage to showcase their books appropriately. This usually helps them prioritise and determine what they want,” she explains.</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="pt-3"><span class="sub-color"> Saumya Khajanchi </span></h2>
                                <p class="pb-3"><span class="profile-text"> Publishing Consultant </span> </p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="team-card text-center">
                                <div className="team-content">
                                    <div class="team-content-overlay fadeIn-bottom"></div>
                                    <div className="team-img">
                                        <img src="../assets/img/team/Samiksha.webp" className="img-fluid" />
                                    </div>
                                    <div className="team-descrition">
                                        <div className="team-overlay-text">
                                            <p>Samiksha is an MBA in Finance & Marketing who excels in outlining marketing strategies. So far, she has aided several newbie authors in publishing their books and has been lauded by many for the extra mile she tends to walk to guide them.</p>
                                            <p>"I make it a point to explain to the authors in detail, the services and marketing tools they can leverage to showcase their books appropriately. This usually helps them prioritise and determine what they want", she explains.</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="pt-3"><span class="sub-color"> Samiksha Singh </span></h2>
                                <p class="pb-3"><span class="profile-text"> Publishing Consultant </span> </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="team-card text-center">
                                <div className="team-content">
                                    <div class="team-content-overlay fadeIn-bottom"></div>
                                    <div className="team-img">
                                        <img src="../assets/img/team/Deepshikha.webp" className="img-fluid" />
                                    </div>
                                    <div className="team-descrition">
                                        <div className="team-overlay-text">
                                            <p>Deepshikha is a commerce graduate and an avid reader. Much like her comrades, she is required to handhold Authors looking to publish their books. “I usually approach the task by explaining the services and marketing strategies that authors can deploy to advertise their beloved masterpieces”, she says</p>
                                            <p>“I’ve spent a significant portion of my career interacting with people, and getting to know what they want. Talking to Authors, especially the new ones, helps me understand their motivations”, she concludes with visible enthusiasm.</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="pt-3"><span class="sub-color"> Deepshikha Mishra </span></h2>
                                <p class="pb-3"><span class="profile-text"> Publishing Consultant </span> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}
export default About;