import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./pages/header";
import Footer from "./pages/footer";
import Home from "./pages/home";
import About from "./pages/about";
import PublishingPackage from "./pages/publishing-package";
import Ebook from "./pages/ebook";
import PaperBack from "./pages/paper-back";

function App() {
  return (
    <>
       <BrowserRouter>
       <Header />
       <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/publishing-package" element={<PublishingPackage />}></Route>
        <Route path="/ebook" element={<Ebook />}></Route>
        <Route path="/paper-back" element={<PaperBack />}></Route>
        
        
      </Routes>
      <Footer />
       </BrowserRouter>
    </>
  );
}

export default App;
