import React,  { useState } from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
function Header (){
return(
<>
<Navbar expand="lg"  sticky="top" className="custom-shadow">
  <Container fluid>
<Navbar.Brand href="/"><img src="../assets/img/logo/bfc-logo.png" alt='logo' className="img-fluid logo"/></Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav" className="justify-content-around">
    <ul className="navbar-nav main-menu ml-auto ">
      <li><a href="/">Home</a></li>
      <li><a href="/about">About</a></li>
      <li className="menu_has_children"><a href="/publishing-package">Packages</a>
      <ul className="sub-menu">
        <li><a href="/eBook">eBook</a></li>
        <li><a href="/paper-back">Paperback</a></li>
      </ul>
    </li>
    <li><a href="/royalty-calculator">Royalty Calculator</a></li>
    <li className="menu_has_children"><a href="#0"> Services</a>
    <ul className="sub-menu">
      <li><a href="/sip-calculator"> Editorial Services</a></li>
      <li><a href="/retirement-planning-calculator">Designing Services</a></li>
      <li><a href="/child-education-planning-calculator">Marketing Services</a></li>
      <li><a href="/child-marriage-planning-calculator"> Distribution Services</a></li>
      <li><a href="/home-loan-emi-calculator"> Author Support</a></li>
    </ul>
  </li>
  <li><a href="/contact-us">Author Dashboard</a></li>
  <li><a href="/contact-us">Book Store</a></li>
  <li><a href="https://bfccapital.com/blog/" target="_blank">Blogs</a></li>
  <li><a href="/contact-us">Contact Us</a></li>
  <li><a href="">KLF</a></li>
</ul>

</Navbar.Collapse>
</Container>
</Navbar>

</>
);
}
export default Header;