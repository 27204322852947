const sampleData =
    [
        {
            name: "Adarsh Singh",
            img: "../assets/img/testimonial/adarsh.webp",
            content: "It was nice to come in contact with BFC Publications, they assisted me in all possible ways, handling all queries and grievances at their topmost priority. I am grateful to my Project Manager for her consistent guidance and humility throughout this journey. During this pandemic time, she was always available virtually to help me with everything I needed for my book. I look forward to work in association with BFC Publications again for my new literary works."
        },
        {
            name: "Nitin Sharma",
            img: "../assets/img/testimonial/nitin.webp",
            content: "Experience is very good. The BFC Publications team delivered everything that was promised. As a first-time author, I could find nothing to criticize."
        },
        {
            name: "Samarjeet Singh",
            img: "../assets/img/testimonial/Samarjeetsingh.webp",
            content: "It was a really wonderful experience which I have gone through with BFC Publications and special thanks to my Project Manager as I really appreciate the way she worked in a professional manner and patiently dealt with new authors like me and it astonished me that the outcome and quality of my book was much more than I expected. I will be very soon coming up with more of my books in 2021."
        },
        {
            name: "Subhash Kumar",
            img: "../assets/img/testimonial/subhash.webp",
            content: "BFC Publications Private Limited, Lucknow is one of the renowned publications of India that have published my two books namely - Gustakhi and Preyasi. I express my gratitude towards my Publishing Consultant for all his dedicated help and support on this project and also my Project Manager whose experience and knowledge in the publishing field was indispensable."
        },
        {
            name: "Roshan Pandey",
            img: "../assets/img/testimonial/roshan.webp",
            content: "First of all, I want to thank all the BFC Publications team members for giving their hands to make my dream come true. As it was my first attempt to write a book, I really appreciate your good rapport with the authors and the publication's neat and rapid process."
        },
        {
            name: "शरद सिंह",
            img: "../assets/img/testimonial/sharad-singh.webp",
            content: "यह पुस्तक बीएफसी पब्लिकेशंस द्वारा प्रकाशित हुई है। इसका पेपर वर्क एवं प्रकाशन कार्य उत्तम है। कवर पृष्ठ भी सुंदर रूप से बनाया गया है। अनसुलझे प्रश्न (कथा संग्रह), जीवन संघर्ष (उपन्यास), लालसा, मेरी स्मृतियां ( काव्य संग्रह ) के पश्चात मेरी एक और त्वरित प्रकाशित पुस्तक अहसास (कथा संग्रह) आपके समक्ष प्रस्तुत है। यह पुस्तक आपको अमेजान, फ्लिपकार्ट आदि पर मिल जायेगी। पढ़कर अपनी प्रतिक्रिया दें।"
        },
        {
            name: "Pradeep Chaturvedi",
            img: "../assets/img/testimonial/pradeep.webp",
            content: "BFC Publications is undoubtedly a very reliable publishing platform. The team is extremely helpful and well coordinated. I am especially happy with the quality of editing, and my book cover. Job well-done team."
        },
        {
            name: "महेश चंद्र भट्ट",
            img: "../assets/img/testimonial/mahesh-bhatt.webp",
            content: "बीएफसी पब्लिकेशंस का कार्य सहयोग पूर्ण रहना पुस्तक के मुख्य पृष्ठ को आकर्षित करता है। काव्य को ठीक तरह से छापा गया है। सहयोग पूर्ण वातावरण से ही पुस्तक को छापने में सफलता मिली है। पुस्तक की एडिटिंग / प्रिंटिंग / प्रूफरीडिंग अच्छी है।"
        },
        {
            name: "C.B. Sharma",
            img: "../assets/img/testimonial/CB-Sharma.webp",
            content: "Thank you team BFC. Only you guys could have pulled this off in time. You have earned a repeat customer."
        },
        {
            name: "Ashok Kumar",
            img: "../assets/img/testimonial/Ashok-author.webp",
            content: "I had a very good experience with BFC Publications, for the publication of my book, the response was always given in a transparent manner for all my queries since it was my first book."
        },
        {
            name: "Varad Agrawal",
            img: "../assets/img/testimonial/VaradAgrawal.webp",
            content: "I would definitely endorse BFC publications because it is a team of professionals . Your services are really nice and deserve a five star. My queries were very well entertained and I am satisfied with the work."
        },
        {
            name: "Manaya Harsha",
            img: "../assets/img/testimonial/ManayaHarsha.webp",
            content: "Right from the first talks with the consultant to the publishing part, my experience with BFC publications has been a happy one. From editing to designing everything has gone smoothly. My Project Manager has been extremely helpful in the entire process. I'm glad I made a right choice. A happy Author."
        },
        {
            name: "Kali Das Pandey (Film Journalist/Advocate)",
            img: "../assets/img/testimonial/KaliDasPandey.webp",
            content: "My book came out better than I had imagined. The cover, layout, interior, everything was awesome. Bravo!! BFC. My name has also been added to the history of the long-grossing film 'Sholay' in the 70s. This is the gift of the BFC Publication."
        },
        {
            name: "Shweta Gaur",
            img: "../assets/img/testimonial/shweta.webp",
            content: "BFC Publications is one of the best publications in the market. They gave good facilities to the author as per their need. The work done in the book is incredible, which encourages me to publish more books from BFC Publications. They have made a fabulous design for the cover of the book, and the interior matter is likewise in effect. If I have to grade this publication, I would like to give 9 out of 10 for their immense support and smooth working."
        },
        {
            name: "Priya Singh",
            img: "../assets/img/testimonial/priya.webp",
            content: "As an author, I can say that I am glad and satisfied with the working style of BFC Publications. The assigned project manager is so hardworking and sincere. The work is very perfect overall."
        },

    ]

export default sampleData;